<template>
    <div class="keg-model" />
</template>

<script>
    import * as THREE from 'three';
    import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
    import { GUI } from 'three/addons/libs/lil-gui.module.min.js';

    export default {
        data() {
            return {};
        },

        mounted() {
            this.init();

            window.addEventListener('resize', this.onResize);
            window.addEventListener('scroll', this.onScroll);
            document.body.addEventListener('mousemove', this.onMouseMove);
        },

        beforeUnmount() {
            window.removeEventListener('resize', this.onResize);
            window.removeEventListener('scroll', this.onScroll);
            document.body.removeEventListener('mousemove', this.onMouseMove);
        },

        methods: {
            init() {
                const gui = new GUI();
                gui.hide();

                this.scene = new THREE.Scene();
                this.camera = new THREE.PerspectiveCamera(50, this.$el.offsetWidth / this.$el.offsetHeight, 0.1, 5000);

                this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
                this.renderer.outputEncoding = THREE.sRGBEncoding;
                this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
                this.renderer.toneMappingExposure = 0.5;
                this.renderer.capabilities.isWebGL2 = true; // Enable WebGL2
                this.renderer.shadowMap.enabled = true;
                this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
                this.renderer.setPixelRatio(window.devicePixelRatio);
                this.renderer.setSize(this.$el.offsetWidth, this.$el.offsetHeight);

                this.$el.appendChild(this.renderer.domElement);

                this.camera.position.set(0, 360, 600);
                this.camera.rotation.set(-0.425, 0, 0);

                const camFolder = gui.addFolder('Camera');
                camFolder.add(this.camera.position, 'x', 0, 1000);
                camFolder.add(this.camera.position, 'y', 0, 1000);
                camFolder.add(this.camera.position, 'z', 0, 1000);
                camFolder.add(this.camera.rotation, 'x', -1, 1);
                camFolder.add(this.camera.rotation, 'y', -1, 1);
                camFolder.add(this.camera.rotation, 'z', -1, 1);

                const light1 = new THREE.DirectionalLight(0xffffff, 2);
                light1.position.set(-120, 1200, 280);
                light1.castShadow = true;
                light1.shadow.bias = -0.0001;
                light1.shadow.darkness = 0.5;
                light1.shadow.mapSize.width = 1024 * 4;
                light1.shadow.mapSize.height = 1024 * 4;
                light1.shadow.radius = 100;
                light1.shadow.blurSamples = 25;
                light1.shadow.camera.near = 0.1;
                light1.shadow.camera.far = 5000;
                light1.shadow.camera.left = -500;
                light1.shadow.camera.right = 500;
                light1.shadow.camera.top = 500;
                light1.shadow.camera.bottom = -500;

                this.scene.add(light1);

                const spotlight1Folder = gui.addFolder('SpotLight 1');
                spotlight1Folder.add(light1.position, 'x', -2000, 2000);
                spotlight1Folder.add(light1.position, 'y', -2000, 2000);
                spotlight1Folder.add(light1.position, 'z', -2000, 2000);
                spotlight1Folder.add(light1, 'intensity', 0, 3);
                spotlight1Folder.add(light1, 'castShadow');
                spotlight1Folder.add(light1.shadow, 'bias', -0.01, 0.01);
                spotlight1Folder.add(light1.shadow, 'radius', 0, 10);
                spotlight1Folder.add(light1.shadow.camera, 'near', 0.1, 1000);
                spotlight1Folder.add(light1.shadow.camera, 'far', 0.1, 1000);

                const light2 = new THREE.DirectionalLight(0xffffff, 1);
                light2.position.set(780, 500, 820);
                light2.castShadow = false;
                light2.shadow.bias = -0.0001;
                light2.shadow.mapSize.width = 1024 * 4;
                light2.shadow.mapSize.height = 1024 * 4;
                light2.shadow.radius = 100;
                light2.shadow.blurSamples = 25;
                light2.shadow.camera.near = 0.1;
                light2.shadow.camera.far = 5000;
                light2.shadow.camera.left = -500;
                light2.shadow.camera.right = 500;
                light2.shadow.camera.top = 500;
                light2.shadow.camera.bottom = -500;
                this.scene.add(light2);

                const spotlight2Folder = gui.addFolder('SpotLight 2');
                spotlight2Folder.add(light2.position, 'x', -2000, 2000);
                spotlight2Folder.add(light2.position, 'y', -2000, 2000);
                spotlight2Folder.add(light2.position, 'z', -2000, 2000);
                spotlight2Folder.add(light2, 'intensity', 0, 3);
                spotlight2Folder.add(light2, 'castShadow');
                spotlight2Folder.add(light2.shadow, 'bias', -0.01, 0.01);
                spotlight2Folder.add(light2.shadow, 'radius', 0, 10);
                spotlight2Folder.add(light2.shadow.camera, 'near', 0.1, 1000);
                spotlight2Folder.add(light2.shadow.camera, 'far', 0.1, 1000);

                // Create the shadow plane
                const shadowPlaneGeometry = new THREE.PlaneGeometry(1000, 1000);
                const shadowPlaneMaterial = new THREE.ShadowMaterial();
                shadowPlaneMaterial.opacity = 0.5;
                const shadowPlane = new THREE.Mesh(shadowPlaneGeometry, shadowPlaneMaterial);
                shadowPlane.receiveShadow = true;
                shadowPlane.rotateX(-Math.PI / 2);
                shadowPlane.position.y = -1;
                this.scene.add(shadowPlane);

                // Create the actual plane
                const planeGeometry = new THREE.PlaneGeometry(1000, 1000);
                const planeMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0 });
                const plane = new THREE.Mesh(planeGeometry, planeMaterial);
                plane.rotateX(-Math.PI / 2);
                plane.position.y = -1;
                this.scene.add(plane);

                const loader = new GLTFLoader();

                loader.load('/dist/models/keg.glb', gltf => {
                    this.ob = gltf.scene;
                    this.ob.rotation.y = 2.25;

                    const [ mesh ] = this.ob.children;
                    const [ body, lid ] = mesh.children;

                    light1.target = mesh;
                    light2.target = mesh;

                    // Body material is white plastic.
                    const bodyMaterial = new THREE.MeshStandardMaterial({
                        color: 0xffffff, // White color
                        // opacity: 0.99, // Semi-transparent
                        transparent: false, // Enable transparency
                        roughness: 0.5, // Polyethylene has some roughness to it
                        metalness: 0.2, // Polyethylene is non-metallic
                    });

                    // Lid material is blue plastic.
                    const lidMaterial = new THREE.MeshStandardMaterial({
                        color: 0x01A1ED, // White color
                        // opacity: 0.99, // Semi-transparent
                        transparent: false, // Enable transparency
                        roughness: 0.5, // Polyethylene has some roughness to it
                        metalness: 0.5, // Polyethylene is non-metallic
                    });

                    body.material = bodyMaterial;
                    lid.material = lidMaterial;

                    this.ob.traverse(n => {
                        if (n.isMesh || n.isLight) {
                            n.castShadow = true;
                            n.receiveShadow = true;

                            if (n.material.map) {
                                n.material.map.anisotropy = 32;
                            }
                        }
                    });

                    this.scene.add(this.ob);

                    this.render();
                }, event => {
                    const percent = event.loaded / event.total * 100;
                    console.info(`${percent.toFixed(2)}%`);
                }, error => {
                    console.error(error);
                });
            },
            render() {
                this.renderer.render(this.scene, this.camera);
            },
            onResize() {
                // Update the camera aspect ratio and renderer size.
                this.camera.aspect = this.$el.offsetWidth / this.$el.offsetHeight;
                this.renderer.setSize(this.$el.offsetWidth, this.$el.offsetHeight);
                this.camera.updateProjectionMatrix();
            },
            onScroll() {
                if (!this.ob) {
                    return;
                }

                // Only rotate the model on mobile devices.
                if (window.innerWidth >= 768) {
                    return;
                }

                // Only rotate if the model is in view.
                if (window.scrollY > this.$el.offsetTop + this.$el.offsetHeight) {
                    return;
                }

                // Animate this.ob.rotation.y based on the scroll position.
                this.ob.rotation.y = 2.25 + (window.scrollY / window.innerHeight * 2);

                requestAnimationFrame(this.render);
            },
            onMouseMove(event) {
                if (!this.ob) {
                    return;
                }

                // Don't rotate the model on mobile devices.
                if (window.innerWidth < 768) {
                    return;
                }

                // Only rotate if the model is in view.
                if (window.scrollY > this.$el.offsetTop + this.$el.offsetHeight) {
                    return;
                }

                const { x } = event;

                // Animate this.ob.rotation.y based on the mouse position.
                this.ob.rotation.y = 2.25 + (x / window.innerWidth * 2);

                requestAnimationFrame(this.render);
            },
        },
    };
</script>

<style lang="postcss">
    .keg-model {
        @apply block relative w-full aspect-w-1 aspect-h-1;
    }
</style>
