<template>
    <div class="main-menu" :class="{ 'main-menu--mounted': isMounted }">
        <div class="container h-full">
            <div class="relative flex items-center h-full">
                <div class="container h-full w-full py-32">
                    <nav class="flex justify-center h-full">
                        <ul class="my-auto">
                            <li
                                v-for="(node, index) in nodes"
                                :key="node.id"
                                class="menu__node"
                                :style="{
                                    transitionDelay: `${index * 100}ms`,
                                }"
                            >
                                <a v-bind="node.attributes" v-text="node.text" />
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

    export default {
        props: {
            nodes: {
                type: Array,
                required: true,
            },
            isMenuOpen: {
                type: Boolean,
                required: true,
            },
        },

        emits: [ 'close' ],

        data() {
            return {
                isMounted: false,
            };
        },

        mounted() {
            disableBodyScroll(this.$el, {
                reserveScrollBarGap: true,
            });

            setTimeout(() => {
                this.isMounted = true;
            }, 100);
        },

        beforeUnmount() {
            enableBodyScroll(this.$el);
        },

        methods: {
            onToggleMenu() {
                this.$emit('close');
            },
        },
    };
</script>

<style>
    .main-menu {
        @apply block fixed inset-0 bg-primary-500 text-white font-heading z-50;
    }

    .menu__node {
        @apply block opacity-0 mb-6 last:mb-0;
        @apply transition-all duration-500 ease-in-out;

        transform: translateY(-100%);
    }

    .menu__link {
        @apply text-4xl text-white/60;
        @apply transition-colors duration-300 ease-in-out;

        &--active,
        &:hover {
            @apply text-white;
        }
    }

    .main-menu--mounted .menu__node {
        @apply opacity-100;

        transform: translateY(0);
    }
</style>
