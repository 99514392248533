<template>
    <div class="tilt-el">
        <slot />
    </div>
</template>

<script>
    import VanillaTilt from 'vanilla-tilt';

    export default {
        mounted() {
            VanillaTilt.init(this.$el, {
                max: 4,
                reverse: true,
                gyroscope: false,
                'full-page-listening': true,
            });

            this.$el.addEventListener('tiltChange', this.onTiltChange);
        },

        beforeUnmount() {
            this.$el.removeEventListener('tiltChange', this.onTiltChange);
            this.$el.vanillaTilt.destroy();
        },

        methods: {
            onTiltChange(event) {
                if (!event.target) {
                    return;
                }

                event.target.style.transform = `${event.target.style.transform} translateZ(0)`;
            },
        },
    };
</script>

<style lang="postcss" scoped>
    .tilt-el {
        backface-visibility: hidden;
        -webkit-filter: blur(0);
    }
</style>
